import React from "react"

import "./cards.scss"

const bullets = [
  {
    number: "1",
    title: "Serwis sprzątający",
    text:
     '- Profesjonalny serwis HSK dla Hoteli i Apartamentów,<br/>- Utrzymanie czystości wewnątrz i na zewnątrz obiektów ,<br/>- Pełna elastyczność w zakresie godzin i wymogów klienta ,<br/>- Prace konserwatorskie, <br/>- Serwis dzienny,' 
  },
  {
    number: "2",
    title: "Gastronomia",
    text: "- Pomoc Kuchenna,<br/>- Obsługa Kelnerska i Barmańska,<br/>- Młodszy Kucharz ,<br/>- Szef Kuchni",
  },
  {
    number: "3",
    title: "Osiedla",
    text:
      "- Sprzątanie powierzchni wspólnych,<br/>- Obsługa terenów zewnętrznych,<br/>- Prace serwisowo – konserwatorskie",
  },
  {
    number: "4",
    title: "Kontrola",
    text:
      "Gwarancją jakości wykonanych usług jest aktywny udział wyznaczonego koordynatora obiektu który sprawdza wszystkie etapy dokonanych prac.",
  },

  // {
  //   number: "5",
  //   title: "Wsparcie",
  //   text: "Wsparcie serwisowe w okresie najmu obiektów.",
  // },
]

const Cards = () => {
  console.log(bullets[0].text)
  return (
    <>
      <div className="cards">
        {bullets.map( tile => (
          <div className="cards__card" key={tile.title}>
            <div>
              <h4>{tile.number}</h4>
              <h3>{tile.title}</h3>
              <div style={{'textAlign':'left', 'lineHeight':'1.5'}} dangerouslySetInnerHTML={{ __html:tile.text }} />
           
            </div>
          </div>
        ) )}
      </div>
    </>
  )
}

export default Cards
