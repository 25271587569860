import photo1 from "../../assets/images/photos/a.jpg"
import photo2 from "../../assets/images/photos/b.jpg"
import photo3 from "../../assets/images/photos/c.jpg"
import photo4 from "../../assets/images/photos/d.jpg"
// import photo5 from "../../assets/images/photos/e.jpg"
import photo6 from "../../assets/images/photos/f.jpg"
// import photo7 from "../../assets/images/photos/g.jpg"
import photo8 from "../../assets/images/photos/h.jpg"
// import photo9 from "../../assets/images/photos/i.jpg"
import photo10 from "../../assets/images/photos/j.jpg"
// import photo11 from "../../assets/images/photos/k.jpg"


export const photos = [
  {
    src: photo1,
    width: 3,
    height: 2,
  },
  {
    src: photo2,
    width: 3,
    height: 2,
  },
  {
    src: photo3,
    width: 3,
    height: 2,
  },
  {
    src: photo4,
    width: 3,
    height: 5,
  },
  // {
  //   src: photo5,
  //   width: 4,
  //   height: 2,
  // },
  {
    src: photo6,
    width: 3,
    height: 2,
  },
  // {
  //   src: photo7,
  //   width: 3,
  //   height: 2,
  // },
  {
    src: photo8,
    width: 3,
    height: 2,
  },
  // {
  //   src: photo9,
  //   width: 3,
  //   height: 2,
  // },
  {
    src: photo10,
    width: 3,
    height: 2,
  },
  // {
  //   src: photo11,
  //   width: 2,
  //   height: 3,
  // },
  // {
  //   src: photo12,
  //   width: 3,
  //   height: 2,
  // },
  // {
  //   src: photo13,
  //   width: 3,
  //   height: 2,
  // },
]
