import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/Header"

import Title from "../components/title/Title"
import Cards from "../components/cards/Cards"
import Portfolio from "../components/portfolio/Portfolio"
import Form from "../components/form/Form"
import Cookies from "../components/cookies/Cookies"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="main">
      <Header />
      <section id="benefits">
        <Cards />
      </section>
      <section id="about">
        <Title text={"O firmie"} />

        <p style={{ textAlign: "left" }}>
        Firma HoReCa Team Sp. z o.o. powstała w celu zapewnienia kompleksowego wsparcia dla branży HoReCa dostarczając doświadczaną kadrę pracowniczą  w całym zakresie potrzeb klientów. 
  Naszym największym zasobem są nasi pracownicy. Przykładamy najwyższą staranność w doborze pracujących dla nas osób wiedząc, że tylko serwis na najwyższym poziomie może spotkać się z zadowoleniem i pozytywna rekomendacja naszych klientów.
        </p>

          </section>

      <section id="cooperation">
        <Title text={"Współpraca"} />

        <p style={{ textAlign: "left" }}>
        W związku z rozwojem przedsiębiorstwa powołaliśmy spółkę Worker Home Sp. z o.o. która zajmuję się kompleksową obsługą kwater pracowniczych. Synergia która powstała dzięki tej współpracy umocniła naszą pozycję i pozwoliła na zwiększenie skali działania, utrzymując najwyższy standard usług. 
        </p>
        <p style={{ textAlign: "left" }}>
          Pełna informacja dotycząca spółki na stronie: <a target="blank" href="https://www.workerhome.pl">www.workerhome.pl</a>
        </p>
      </section>

      <section id="gallery">
        <Title text={"Galeria"} />
      </section>
      <Portfolio />
      <section>
        <Form />
      </section>
    </div>
    <Cookies />
  </Layout>
)

export default IndexPage
